import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

const Home = () => {
  const { user } = useAuth0();
  return (
    <div>
      Home
      {user && <div>Hello {user?.name}</div>}
    </div>
  );
};

export default Home;
