import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { LogoutButton, LoginButton } from '../index';

const NavBar = () => {
  const {
    // user,
    isAuthenticated,
    // loginWithRedirect,
    // logout,
  } = useAuth0();
  return (
    <div>
       {isAuthenticated && <LogoutButton />}
       {!isAuthenticated && <LoginButton />}
    </div>
  );
};

export default NavBar;